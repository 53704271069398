
export const validatorPhone = (_, value) => {
  const rules = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
  if(value) {
    if(rules.test(value)) {
      return Promise.resolve()
    } else {
      return Promise.reject(new Error('请输入正确的手机号'))
    }
  }
  return Promise.resolve()
}

/**
 * 使用方法：
    rules={[
      { required: true, message: '请输入正确的邮箱' },
      {validator: validatorEmail}
    ]}
*/
export const validatorEmail = (_, value) => {
  const rules = /^[A-Za-z0-9]+@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/
  if(value) {
    if(rules.test(value)) {
      return Promise.resolve()
    } else {
      return Promise.reject(new Error('请输入正确的邮箱'))
    }
  }
  return Promise.resolve()
}
/**
 * 首尾出现空格的校验
*/
export const validatorTrim = (_, value) => {
  if (typeof value === 'string') {
    const rules = value.trim()
    if(value) {
      if(rules===value) {
        return Promise.resolve()
      } else {
        return Promise.reject(new Error('首尾不能输入空格'))
      }
    }
  }
  return Promise.resolve()
}