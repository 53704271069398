import React, { useEffect, useState } from 'react'
import * as PcComponents from '@components/PcComponents/homeMods'
import { Skeleton } from 'antd';
import { getCms } from '@/api/apis';
import './index.scss'

export default function PcHome(){
  const [mods, setMods] = useState([])
  useEffect(() => {
    const { content_id, param_id } = window.page.body
    getCms({
      params: {terminal: 'Ws_Pc', content_id, param_id}
    }).then(res => {
      res.page_data.map(item => {
        if(item.module_param?.css_style) {
          try {
            item.module_param.css_style = JSON.parse(item.module_param.css_style)
          } catch (error) {
            item.module_param.css_style = {}
          }
        } else {
          item.module_param.css_style = {}
        }
        return item
      })
      setMods(res.page_data)
    })
  }, [])

  return (
    <div className="pc-home">
      {
        mods.map(item => {
          const Com = PcComponents[item.module_id]
          return Com && <Com key={item.sort} data={item} />
        })
      }

      {
        
        mods.length===0 && [1,2,3,4,5].map(item => {
          return (
            <Skeleton active loading key={item} />
          )
        })
      }
    </div>
  )
}
