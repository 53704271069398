/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react'
import { Carousel } from 'antd'
import * as BaseMedias from '../baseMedia';
import './index.scss'
import LeftIcon from '@assets/img/swiper-left.png';
import RightIcon from '@assets/img/swiper-right.png';
import pxToRem from '@utils/htmlpxrem';

// 走马灯  图片/视频

export class CommSwiper extends Component {
  // state = {
  //   carousel: React.createRef(),
  //   timerArr: []
  // }

  // componentDidMount() {
  //   let swith_time = this.props.data.module_data[0]?.swith_time > 0 ? this.props.data.module_data[0]?.swith_time : 5
  //   let timer = setTimeout(() => {
  //     this.state.carousel.current && this.state.carousel.current.next()
  //     this.setState({
  //       timerArr: []
  //     })
  //     clearTimeout(timer)
  //   }, swith_time*1000+500)
  //   this.setState({
  //     timerArr: [timer]
  //   })
  // }

  // componentWillUnmount() {
  //   if(this.state.timerArr.length> 0) {
  //     this.state.timerArr.forEach(t => {
  //       clearTimeout(t)
  //     })
  //   }
  // }

  // beforeChange = (from, to) => {
  //   if(this.state.timerArr.length>0) { // 清除计时器
  //     this.state.timerArr.forEach(t => {
  //       clearTimeout(t)
  //     })
  //     this.setState({
  //       timerArr: []
  //     })
  //   }
  //   let swith_time = this.props.data.module_data[to]?.swith_time > 0 ? this.props.data.module_data[to]?.swith_time : 5
  //   const timer = setTimeout(() => {
  //     this.state.carousel.current && this.state.carousel.current.next()
  //     this.setState({
  //       timerArr: []
  //     })
  //     clearTimeout(timer)
  //   }, swith_time*1000+500)
  //   this.setState({
  //     timerArr: [timer]
  //   })
  // }

  render () {
    return (
      <div className="full-screen-carousel" style={this.props.data?.module_param?.css_style}>
        <Carousel
          autoplay={true}
          dots={true}
          // ref={this.state.carousel}
          // beforeChange={(from, to) => this.beforeChange(from, to)}
        >
          {
            this.props.data.module_data.map((item, index) => {
              return (
                <a href={item?.ws_url} target={item?.ws_url_target || ''} className="carousel-item" key={index}>
                  <BaseMedias.BaseMedia mediaData={item} isVideo={item?.show_type==='video'} mod="CommSwiper" />
                </a>
              )
            })
          }
        </Carousel>
      </div>
    )
  }
}

// 1张图片/视频 
export class ImgOne extends Component {
  render () {
    let width = this.props.data?.module_param?.width || '100%';
    let height = this.props.data?.module_param?.height || 'auto';
    return (
      this.props.data.module_data.map((item,index) => {
        return (
          <a href={item?.ws_url} key={index} className="img-component-big-img" style={{width, height, ...this.props.data?.module_param?.css_style}} target={item?.ws_url_target || '' || ''}>
            <BaseMedias.BaseMedia mediaData={item} isVideo={item?.show_type==='video'} />
          </a>
        )
      })
    )
  }
}

// 2张图片/视频
export class ImgTwo extends Component {
  state = {
    last_html : ''
  }
  componentDidMount() {
    if(this.props.data.module_param?.customize_html) {
      // 行内标签px转rem
      pxToRem(this.props.data.module_param?.customize_html || '').then(str => {
        this.setState({
          last_html: str
        })
      })
    }
  }
  render () {
    let width = this.props.data?.module_param?.width || '100%';
    let height = this.props.data?.module_param?.height || 'auto';
    return (
      <div className="img-two fx" style={{width, height, ...this.props.data?.module_param?.css_style}}>
        {
          this.props.data.module_data.map((item,index) => {
            return (
            <a href={item?.ws_url} key={index} className="img-item" target={item?.ws_url_target || ''}>
              <BaseMedias.BaseMedia mediaData={item} isVideo={item?.show_type==='video'} />
            </a>
            )
          })
        }
        {
          (this.props.data.module_param?.module_layout_show === 'default' && this.state.last_html) &&
          <div
            className="position-center react-html"
            dangerouslySetInnerHTML={{__html: this.state.last_html}}
          >
          </div>
        }
      </div>
    )
  }
}

// 3张图片/视频
export class ImgThree extends Component {
  state = {
    last_html : ''
  }
  componentDidMount() {
    if(this.props.data.module_param?.customize_html) {
      // 行内标签px转rem
      pxToRem(this.props.data.module_param?.customize_html || '').then(str => {
        this.setState({
          last_html: str
        })
      })
    }
  }
  render () {
    let width = this.props.data?.module_param?.width || '100%';
    let height = this.props.data?.module_param?.height || 'auto';
    return (
      <div className="img-three fx" style={{width, height, ...this.props.data?.module_param?.css_style}}>
        {
          this.props.data.module_data.map((item,index) => {
            return (
            <a href={item?.ws_url} key={index} className="img-item" target={item?.ws_url_target || ''}>
              <BaseMedias.BaseMedia mediaData={item} isVideo={item?.show_type==='video'} />
            </a>
            )
          })
        }
        {
          (this.props.data.module_param?.module_layout_show === 'default' && this.state.last_html) &&
          <div
            className="position react-html"
            dangerouslySetInnerHTML={{__html: this.state.last_html}}
          >
          </div>
        }
      </div>
    )
  }
}

export class ImgFour extends Component {
  state = {
    last_html : ''
  }
  componentDidMount() {
    if(this.props.data.module_param?.customize_html) {
      // 行内标签px转rem
      pxToRem(this.props.data.module_param?.customize_html).then(str => {
        this.setState({
          last_html: str
        })
      })
    }
  }
  render () {
    return (
      <div className="img-four" style={this.props.data?.module_param?.css_style}>
        {
          this.props.data.module_data.map((item, index) => {
            return (
              <a href={item.ws_url} target={item?.ws_url_target || ''} className="four-item" key={index}>
                <BaseMedias.BaseMedia mediaData={item} isVideo={item?.show_type==='video'} />
                {
                  item?.title && 
                  <div className="four-title">
                    {item?.title}
                  </div>
                }
                {
                  item?.price && 
                  <div className="four-price">
                    {item.price}
                  </div>
                }
              </a>
            )
          })
        }
        {
          (this.props.data.module_param?.module_layout_show === 'placeholder_right' && this.state.last_html) &&
          <div className="four-item last-item">
            <div
              className="react-html"
              dangerouslySetInnerHTML={{__html: this.state.last_html}}
            >
            </div>
          </div>
        }
      </div>
    )
  }
}

// pc显示5张图片,mob显示1张图片-轮播
export class ImgFiveSwiper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      carousel: React.createRef(),
      showLeftMenu: false,
      showRightMenu: false,
    }
  }

  handleChangeImg = (type) => {
    return () => {
      this.state.carousel.current && this.state.carousel.current[type]()
    }
  }


  beforeChange(from, to) {
    if(to>from) { // 点击右按钮
      if(this.props.data?.module_data.length - 5 === to) {
        this.setState({
          showRightMenu: false
        })
      }
      if(from === 0) {
        this.setState({
          showLeftMenu: true
        })
      }
    } else if(to<from) { // 点击左按钮
      if(to===0) {
        this.setState({
          showLeftMenu: false,
        })
      }
      if(!this.state.showRightMenu){
        this.setState({
          showRightMenu: true
        })
      }
    } else { // 初始化页面
      this.setState({
        showRightMenu: this.props.data?.module_data.length>5,
      })
    }
  }

  render() {
    return (
      <div className="img-five-carousel" style={this.props.data?.module_param?.css_style}>
        <div className="carousel">
          <Carousel
            dots={false}
            ref={this.state.carousel}
            slidesToShow={5}
            infinite={false}
            beforeChange={(from, to) => this.beforeChange(from, to)}
          >
            {
              this.props.data?.module_data.map((item, index) => {
                return (
                  <div className="good-item" key={index}>
                    <a href={item.ws_url} target={item?.ws_url_target || ''} className="good-link">
                      <BaseMedias.BaseMedia mediaData={item} isVideo={item?.show_type==='video'} />
                    </a>
                    <div className="good-info">
                      {
                        item?.title &&
                        <p className="good-name">{item?.title}</p>
                      }
                      {
                        item?.price &&
                        <p className="good-price">{item?.price}</p>
                      }
                    </div>
                  </div>
                )
              })
            }
          </Carousel>
          {
            this.state.showLeftMenu && <img alt="" className="swiper-menu menu-left" src={LeftIcon} onClick={this.handleChangeImg('prev')} />
          }
          {
            this.state.showRightMenu && <img alt="" className="swiper-menu menu-right" src={RightIcon} onClick={this.handleChangeImg('next')} />
          }
        </div>
      </div>
    )
  }
}

// 纯文字模块
export class FloatHtml extends Component {
  state = {
    module_data: []
  }
  componentDidMount() {
    this.props.data.module_data.forEach(item => {
      pxToRem(item?.layout?.customize_html ||item?.customize_html || '').then(str => {
        this.setState({
          module_data: [...this.state.module_data, str]
        })
      })
    })
  }
  render () {
    return (
      this.state.module_data.map((item, index) => {
        return (
            <div
              className="empty-block"
              key={index}
              style={this.props.data?.module_param?.css_style}
            >
              {
                item && 
                <div
                  dangerouslySetInnerHTML={{__html: item}}
                  className="react-html empty-cont">
                </div>
              }
            </div>
        )
      })
    )
  }
}



// 1张图+文字---两栏位
export class TextImgHtml extends Component {
  state = {
    module_data: []
  }
  componentDidMount() {
    this.props.data?.module_data.forEach(item => {
      let itemData = Object.assign({} ,item)
      pxToRem(item.customize_html).then(res => {
        itemData.customize_html = res;
        this.setState({
          module_data: [...this.state.module_data, itemData]
        })
      })
    })
  }
  render () {
    // sort=1图片在左边，4图片在右边craftsmanship
    return (
      this.state.module_data.map((item,index) => {
        return (
          <div className="img-one-text" key={index}  style={this.props.data?.module_param?.css_style}>
            {
              item.arrange === 'image2text' &&
              <>
                <div style={{width: '50%'}}>
                  <img className="left-img" alt="" src={item.pc_source_url}></img>
                </div>
                <div className="fx-col html-content" style={{width: '50%'}}>
                  <div className="title">
                    {item.title}
                  </div>
                  <div className="react-html flex1" dangerouslySetInnerHTML={{__html: item.content}}>
                  </div>
                </div>
              </>
            }
            {
              item.arrange === 'text2image' &&
              <>
                <div className="fx-col html-content" style={{width: '50%'}}>
                  <div className="title">
                    {item.title}
                  </div>
                  <div className="react-html flex1" dangerouslySetInnerHTML={{__html: item.content}}>
                  </div>
                </div>
                <div style={{width: '50%'}}>
                  <img className="left-img" alt="" src={item.pc_source_url}></img>
                </div>
              </>
            }
            {
              item.arrange === 'video2text' &&
              <>
                <div className="flex1">
                  <video className="left-img" alt="" src={item.pc_source_url} controls preload />
                </div>
                
                <div className="flex1 fx-col html-content">
                  <div className="title">
                    {item.title}
                  </div>
                  <div className="react-html flex1" dangerouslySetInnerHTML={{__html: item.content}}>
                  </div>
                </div>
              </>
            }
            {
              item?.arrange === 'timage2btext' && 
              <div className="fx-col">
                <div className="fx-col">
                  <a href={item?.ws_url} target={item?.ws_url_target || ''}>
                    <img alt="" src={item?.pc_source_url} style={{width:'100vw'}}></img>
                  </a>
                </div>
                <div className="fx-col html-content">
                  <div className="title">
                    {item.title}
                  </div>
                  <div className="react-html flex1" dangerouslySetInnerHTML={{__html: item.content}}>
                  </div>
                </div>
              </div>
            }
            {
              item?.arrange === 'text' && 
              <div className="fx-col">
                <div className="fx-col html-content">
                  <div className="title">
                    {item.title}
                  </div>
                  <div className="react-html flex1" dangerouslySetInnerHTML={{__html: item.content}}>
                  </div>
                </div>
              </div>
            }
            {
              item?.arrange === 'video' && 
              <div className="flex1">
                <video className="left-img" style={{width:'100vw'}} alt="" src={item.pc_source_url} controls preload />
              </div>
            }
            {
              item?.arrange === 'image' && 
              <div className="flex1">
                <img alt="" src={item?.pc_source_url} style={{width:'100vw'}}></img>
              </div>
            }
          </div>
        )
      })
    )
  }
}
