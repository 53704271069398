import 'core-js/es';
import "react-app-polyfill/ie11";
import 'react-app-polyfill/stable';
import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import getWindowPage from './utils/pages'


if (process.env.NODE_ENV === 'development') {
  React.Component.prototype.page = window.page = getWindowPage()
} else {
  React.Component.prototype.page = window.page
}

ReactDOM.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <App />
  ,
  document.getElementById('root')
)

reportWebVitals()
