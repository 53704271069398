// import { CancelToken } from './http';
/**
 * axios拦截
 * */ 
import {
  LoadingOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import { message } from 'antd';
import { getQuery, sign } from '@utils';

/**
 * 请求的url中有STORE_VIEW，
 * 在请求拦截中将它替换掉，
 * 换成当前window.page中的store_view
 * */ 
const reg = /STORE_VIEW/g;
// 取消请求
const CancelToken = axios.CancelToken;
// 保存需要loading的接口的loadingKey
let loadings = []

// 请求拦截
export const req = config => {
  // 获取参数签名
  const query = getQuery(config.url);

  let _data = config.data || {}
  if (Object.keys(query).length) {
    config.url += `&request_sign=${sign({...query, ..._data})}`
  } else {
    config.url += `?request_sign=${sign({...query, ..._data})}`
  }

  // 替换站点
  const store_view = window.page.store_view;
  config.url = config.url.replace(reg, store_view)

// 取消请求
  let cancelReq = null;
  config.cancelToken = new CancelToken(c => {
    cancelReq = c;
  });

  // loading
  if(config.showLoading) {
    const loadingKey = Math.random()+'' // 生成loading的key值
    config.loadingKey = loadingKey
    if(loadings.length===0) {
      message.loading({
        content: 'Loading...',
        key: loadingKey,
        duration: 0,
        icon: <LoadingOutlined />
      })
    }
    loadings.push(loadingKey)
  }

  // token
  if(config.Authorization) {
    const token = localStorage.getItem('token')
    if(token) { // 有token
      config.headers.Authorization = token;
    } else { // 没有token
      // 取消请求
      cancelReq({ msg: '该接口需要token', config });
    }
  }
  return config
}

// 请求拦截-error
export const reqError = err => {
  message.destroy()
  return Promise.reject(err)
}

// 响应拦截
export const res = res => {
  // 有多个接口需要loading时，等待所有接口都返回再关闭loading
  if(res.config.showLoading) {
    loadings.splice(loadings.findIndex(item => item === res.config.loadingKey))
    if(loadings.length===0) {
      message.destroy()
    }
  }

  let result;
  const data = res.data;
  switch (data.code) {
    case 200:
      result = data.data
      break;
    default: 
      data?.message && message.error(data.message || `接口错误${res.config.url}`)
      result = Promise.reject(data);
  }
  return result
}

// 响应拦截-error
export const resError = err => {
  // 清空所有message
  message.destroy()
  loadings = []
  return Promise.reject(err)
}