import React, { Component } from 'react'
import PubSub from 'pubsub-js'
import { subResize } from '@utils/index'
import Pc from './pc'
import Mobile from './mobile'

export default class Footer extends Component {
  componentDidMount () {
    this.subResizeToken = subResize(this.changeDevice)
  }

  componentWillUnmount () {
    PubSub.unsubscribe(this.subResizeToken)
  }

  changeDevice = device => {
    this.setState({ device })
  }

  state = {
    device: ''
  }

  render() {
    const { device } = this.state
    return (
      <footer className="footer">
        {{ pc: <Pc />, mobile: <Mobile /> }[device]}
      </footer>
    )
  }
}
