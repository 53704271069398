import React from 'react'
import Pc from './pc'
import Mobile from './mobile'
import useDevice from '@/hooks/useDevice';
export default function Home() {
  const device = useDevice()
  return (
    <div className="activity">
      {{ pc: <Pc />, mobile: <Mobile /> }[device]}
    </div>
  )
}

