import React, {useEffect, useState} from 'react'
import CommHead from './components/CommHead'
import { getCms } from '@/api/apis';
export default function Head() {
  // 头部类型-目前只有一个头 CommHead
  const [type, setType] = useState('CommHead')
  // 头部数据
  const [data, setData] = useState([])
  useEffect(() => {
    getCms({
      params: {terminal: 'Ws_Mob', content_id: 'commHead', param_id: 'commHead'}
    }).then(res => {
        const navs= res.page_data === undefined ? [] : res?.page_data[0]?.module_data || []
        setData(navs)
        setType(res.template_id || 'CommHead')
    })
  }, [])
  return (
    <div className="home">
      {{ CommHead: <CommHead navs={data} />}[type]}
    </div>
  )
}
