import React, { Component } from 'react'
import './index.scss'
import logo from '@assets/logo.svg'
import logo2 from '@assets/white-logo.svg'
import close from '@assets/img/close.png'
import user from '@assets/img/user.png'
import search from '@assets/img/search.png'
import user2 from '@assets/img/user-white.png'
import search2 from '@assets/img/search-white.png'
import BUS from '@utils/ev.js';
import { searchNav } from '@/api/apis';
import {debounce, throttle} from '@utils'

const path = ['','index'].includes(window.location.pathname.slice(1))

export default class CommHead extends Component {
  constructor(props) {
    super(props)
    this.state = {
      style: {
        backgroundColor: 'transparent',
        color: '#fff'
      },
      subNav: [],
      subNavId: '',
      headLogo: logo2,
      searchIcon: search2,
      userIcon: user2,
      showFree: true, // h-top是否显示
      subNavHeight: '0', // 二级分类+灰色背景的高度
      subNavBoxRef: React.createRef(),
      subNavBoxHeight: '0', // 如果二级分类的高度超过3.77rem，需要手动去设置最小高度
      showSearch: false, // 显示搜索框
      searchTop: '1.89836rem',
      showSearchResult: false, // 显示搜索结果
      searchResultList: []
    }

  }

  componentDidMount () {

    // 初始化时如果不是首页时的默认icon和颜色
    if(!path) {
      this.setState({
        style: { backgroundColor: '#dcddde', color: '#272727',height: '1.89836rem', borderBottom: '1px solid #000' },
        headLogo: logo,
        searchIcon: search,
        userIcon:user,
      })
    }
    this.scrollListener = throttle(() => {
      if(this.state.subNav.length>0 || this.state.showSearch) return;
      // 滚动距离超过100px
      if (window.pageYOffset > 100) {
        let borderBottom;
        if(path) { // 首页时
          borderBottom = '1px solid #dcddde'
        } else {  // 非首页时
          borderBottom = '1px solid #000'
        }
        this.setState({
          style: { backgroundColor: '#dcddde', color: '#272727',height: '0.97352rem', borderBottom },
          headLogo: logo,
          searchIcon: search,
          userIcon: user,
          showFree: false
        })
      }

      if (window.pageYOffset <= 100) {
        let backgroundColor = '#dcddde'
        let color = '#272727'
        let headLogo= logo
        let searchIcon= search
        let userIcon= user
        let borderBottom = '1px solid #000'
        if(path) { // 如果是首页
          backgroundColor = 'transparent'
          color = '#fff'
          headLogo= logo2
          searchIcon= search2
          userIcon= user2
          borderBottom = '1px solid #dcddde'
        }
        this.setState({
          style: { backgroundColor, color, height: '1.89836rem', borderBottom },
          headLogo,
          searchIcon,
          userIcon,
          showFree: true
        })
      }
    }, 20)
    window.addEventListener('scroll', this.scrollListener)
  }

  componentWillUnmount () {
    if (this.scrollListener) {
      window.removeEventListener('scroll', this.scrollListener)
    }
  }

  setSubNav = (sort) => {
    const subData = this.props.navs.find((item, index) => index === sort)
    let subNavHeight;
    if(subData.children.length > 0) {
      subNavHeight = this.state.showFree ? 'calc(100vh - 1.89836rem)' : 'calc(100vh - 0.97352rem)'
    } else {
      subNavHeight = '0'
    }
    this.setState({
      showSearch: false,
      subNavId: sort,
      subNav: subData.children || [],
      subNavBoxHeight: subData.children.length > 0 ? '3.9671rem' : '0',
      subNavHeight
    }, () => {
      // 解决初始加载图片时，高度为0的问题
      let timer = setTimeout(() => {
        let sHeight = this.state.subNavBoxRef.current.scrollHeight;
        let cHeight = this.state.subNavBoxRef.current.clientHeight;
        // console.log(sHeight, cHeight);
        if(sHeight < 100) sHeight = 100
        if(sHeight > cHeight) { // 有滚动条
          this.setState({
            subNavBoxHeight: sHeight + 'px'
          })
        }
        clearTimeout(timer)
      }, 20)
    })
  }
  removeSubNav = () => {
    this.setState({
      subNavId: '',
      subNav: [],
      subNavBoxHeight: '0',
      subNavHeight: '0'
    })
  }

  showSubscribe = () => {
    BUS.emit('showSubscribe', true)
  }

  // 鼠标进入
  enterHeadEvent = () => {
    const height = this.state.showFree ? '1.89836rem' : '0.97352rem'
    this.setState({
      style: { backgroundColor: '#dcddde', color: '#272727',height },
      headLogo: logo,
      searchIcon: search,
      userIcon:user,
    })
  }
  // 鼠标离开
  leaveHeadEvent = () => {
    if(this.state.subNav.length>0 || this.state.showSearch) return;

    const height = this.state.showFree ? '1.89836rem' : '0.97352rem'
    const backgroundColor = this.state.showFree && path ? 'transparent' : '#dcddde'
    const color = this.state.showFree && path ? '#fff' : '272727'
    const headLogo = this.state.showFree && path ? logo2 : logo
    const searchIcon = this.state.showFree && path ? search2 : search
    const userIcon = this.state.showFree && path ? user2 : user
    this.setState({
      style: { backgroundColor, color, height },
      headLogo,
      searchIcon,
      userIcon,
    })
  }

  // 显示搜索框
  showSearchEvent = () => {
    const searchTop = this.state.showFree ? '1.89836rem' : '0.97352rem'
    this.removeSubNav()
    this.setState({
      showSearch: true,
      searchTop
    })
  }
  // 显示搜索框
  hideSearchEvent = () => {
    this.setState({
      showSearch: false
    })
  }

  // 搜索  debounce防抖
  changeSearch = debounce((e) => {
    if(e.target.value) {
      searchNav({
        data: {
          key_words: e.target.value
        }
      }).then(res => {
        this.setState({
          showSearchResult: true,
          searchResultList: res.list
        })
      })
    }
  },1000)


  render() {
    return (
      <div className="pc-header" style={this.state.style} onMouseEnter={() => this.enterHeadEvent()} onMouseLeave={() => this.leaveHeadEvent()}>
        {
          <div className={this.state.showFree ? 'h-top' : 'h-top hide-top'}>
            <p className="language">中国(CNY) | 简体中文</p>
            <p onClick={() => window.location.href='/store'} className="find-store txt-pointer">精品店查询</p>
          </div>
        }
        <div className="h-bottom">
          <h1 className="h-left">
            <a href="/">
              <img src={this.state.headLogo} alt="logo" className="logo" /> 
            </a>
          </h1>
          <ul className="h-mid">
            {
              this.props.navs.map((nav, index) => {
                return <li
                  className={this.state.subNavId === index ? 'active' : ''}
                  key={index}
                  onMouseEnter={() => this.setSubNav(index)}
                >{nav.title}</li>
              })
            }
          </ul>
          <div className="h-right fx">
            <img alt="" className="user-email" src={this.state.userIcon} onClick={() => this.showSubscribe()} />
            <img alt="" src={this.state.searchIcon} onClick={() => this.showSearchEvent()} />
          </div>
        </div>
        {/* 二级菜单 */}
        <div
          className={
            this.state.subNav.length === 0 ? 'sub-nav hide-sub-nav' : 'sub-nav show-sub-nav'
          }
          style={{top: this.state.showFree ? '1.89836rem' : '0.97352rem', height: this.state.subNavHeight}}
        >
          <div 
            className="nav-box"
            style={{"height": this.state.subNavBoxHeight}}
            ref={this.state.subNavBoxRef}
          >
            {
              this.state.subNav.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {/* 文字类型 */}
                    {
                      item.href_type === 'text' && 
                      <dl>
                        <dt >
                          <a href={item.ws_url}>
                            {item.title}
                          </a>
                        </dt>
                        {
                          item?.children.map((itm, indx) => {
                            return <dd key={indx}>
                              <a href={itm.ws_url} target={ itm?.ws_url_target || '' }> {itm.title} </a>
                            </dd>
                          }) 
                        }
                      </dl>
                    }
                    {/* 图片类型 */}
                    {
                      item.href_type === 'image' &&
                      <>
                        {
                          item?.children.map((itm, indx) => {
                            return (
                              <a href={itm.ws_url} key={indx} target={ itm?.ws_url_target || '' } className="sub-img-box">
                              <img className="sub-img" alt="" src={itm.pc_image_url} />
                            </a>
                            )
                          })
                        }

                      </>
                    }

                  </React.Fragment>
                )
              })
            }
          </div>
          <div
            className="shadow"
            style={{display: this.state.subNav.length === 0 ? 'none' : 'block'}}
            onMouseEnter={() => this.removeSubNav(this.state.subNavId)}
            ></div>
        </div>
        {/* 搜索 */}
        <div
          className="search-content fx-col"
          style={{
            display: this.state.showSearch ? 'flex' : 'none',
            top: this.state.searchTop
          }}
        >
          <div className="align-c search-control">
            <img className="search-icon" alt="" src={search} />
            <input className="search-input" onChange={(e) => this.changeSearch(e)} />
            <img className="close-icon" src={close} alt="" onClick={() => this.hideSearchEvent()} />
          </div>
          {
            this.state.showSearchResult &&
            <div className="search-result">
              <div className="search-line"></div>
              <div className="res-content fx">
                <div className="res-left flex1">
                  <div className="res-title">
                    SEARCH RERSULTS
                  </div>
                  <ul className="res-link">
                    {
                      this.state.searchResultList.map((item, index) => {
                        return (
                          <li>
                            <a href={'/'+item.route}>{item.title}</a>
                          </li>
                        )
                      })
                    }
                    {
                      (this.state.searchResultList.length === 0 && this.state.showSearchResult) && 
                      <li>没有搜索到你想要的</li>
                    }
                  </ul>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}
