
// import pxToRem from '@utils/htmlpxrem';
// import { useEffect, useState } from 'react';
import './index.scss'

export function BaseMedia(props) {
  function MediaImg(props) {
    return (
      <img
        className="media"
        src={props.url}
        alt=''
      />
    )
  }
  function MediaVideo(props) {
    return (
      <video 
        className="media"
        src={props.url}
        autoPlay
        muted
        loop
      ></video>
    )
  }
  function HomeModel(props) {
    // px转rem，在ie里卡顿严重，去掉了该功能
    // const [html, setHtml] = useState('')
    // useEffect(() => {
    //   console.log("行内标签px转rem");
    //   // 行内标签px转rem
    //   pxToRem(props?.layout||'').then(str => {
    //     setHtml(str)
    //   })
    // })
    return (
      <div
        className="position-model react-html"
        dangerouslySetInnerHTML={{__html: props?.layout}}
      >
      </div>
    )
  }

  let Media = <MediaImg url={props.mediaData?.pc_image_url || props.mediaData?.pc_source_url} />
  if(props.isVideo) {
    Media = <MediaVideo url={props.mediaData.pc_image_url || props.mediaData.pc_source_url} />
  }
  // 为了适应byfar项目，数据类型改变了
  let customize_html = ''
  try {
    console.log(props.mediaData?.layout);
    if(props.mediaData?.layout.length>0) {
      customize_html = props.mediaData?.layout[0].customize_html
    }
  } catch (error) {
    throw(error)
  }
  return (
    <div className="media_content">
      {Media}
      <HomeModel layout={customize_html} show_type="position" />
    </div>
  )
}
