import { useState, useEffect } from 'react'
import PubSub from 'pubsub-js'
import { subResize } from '@/utils'

// device返回值为pc或mobile，用来区分当前是pc端还是移动端
const useDevice = () => {
  let is_mobi = true;
  let devi = 'pc'
  // 判断是手机还是电脑
  is_mobi = navigator.userAgent.toLowerCase().match(/(ipod|ipad|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i) != null;
  if(is_mobi) devi = 'mobile';
  const [device, setDevice] = useState(devi)
  useEffect(() => {
    const subResizeToken = subResize(device => setDevice(device))
    return () => {
      PubSub.unsubscribe(subResizeToken)
    }
  }, [])
  return device
}

export default useDevice