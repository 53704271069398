import React, {useEffect, useState} from 'react'
import CommFoot from './components/CommFoot'
import { getCms } from '@/api/apis';
export default function Head() {
  // 头部类型-目前只有一个头 CommFoot
  const [type, setType] = useState('CommFoot')
  // 头部数据
  const [data, setData] = useState([])
  useEffect(() => {
    getCms({
      params: {terminal: 'Ws_Pc', content_id: 'CommFoot', param_id: 'CommFoot'}
    }).then(res => {
        const navs= res.page_data === undefined ? [] : res?.page_data[0]?.module_data || []
        setData(navs)
        setType(res.template_id || 'CommFoot')
    })
  }, [])
  return (
    <div className="home">
      {{ CommFoot: <CommFoot navs={data} />}[type]}
    </div>
  )
}
