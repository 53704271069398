
import './index.scss'
import pxToRem from '@utils/htmlpxrem';
import { useEffect, useState } from 'react';
export function BaseMedia(props) {
  let Media = <MediaImg url={props.mediaData?.mob_image_url || props.mediaData?.mob_source_url} />
  if(props.isVideo && (props.mediaData?.mob_image_url || props.mediaData?.mob_source_url)) {
    Media = <MediaVideo url={props.mediaData?.mob_image_url || props.mediaData?.mob_source_url} />
  }
  
  // 为了适应byfar项目，数据类型改变了
  let customize_html = ''
  try {
    console.log(props.mediaData?.layout);
    if(props.mediaData?.layout.length>0) {
      customize_html = props.mediaData?.layout[0].customize_html
    }
  } catch (error) {
    throw(error)
  }
  return (
    <div className="media_content">
      {Media}
      <HomeModel layout={customize_html} show_type="position" />
    </div>
  )
}

function MediaImg(props) {
  return (
    <img
      className="media-img"
      src={props.url}
      alt=''
    />
  )
}
function MediaVideo(props) {
  useEffect(() => {
    document.addEventListener("WeixinJSBridgeReady", function () { 
      window.WeixinJSBridge.invoke('getNetworkType', {}, (e) => {
        document.getElementById('videoid').play()
      })
    })
  }, [])
  return (
    <video 
      className="media-video"
      src={props.url}
      muted
      loop
      autoPlay
      x5-video-player-type="h5"
      webkit-playinline
      playsInline
      id="videoid"
    ></video>
  )
}

export function HomeModel(props) {
  const [html, setHtml] = useState('')
  useEffect(() => {
    // 行内标签px转rem
    pxToRem(props?.layout||'').then(str => {
      setHtml(str)
    })
  })
    return (
      <div
        className="position-model react-html"
        dangerouslySetInnerHTML={{__html: html}}
      ></div>
    )
}