import React, { Component } from 'react'
import * as MobileComponents from '@components/MobileComponents/homeMods'
import { getCms } from '@/api/apis';
import { Skeleton } from 'antd';
import './index.scss'
export default class Mob extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mods: [],
      ske: true // 骨架屏
    }
  }
  componentDidMount () {
    const { content_id, param_id } = this.page.body
    getCms({
      params: {terminal: 'Ws_Mob', content_id, param_id}
    }).then(res => {
      res.page_data.map(item => {
        if(item.module_param?.css_style) {
          try {
            item.module_param.css_style = JSON.parse(item.module_param.css_style)
          } catch (error) {
            item.module_param.css_style = {}
          }
        } else {
          item.module_param.css_style = {}
        }
        return item
      })
      this.setState({
        ske: false,
        mods: res.page_data || []
      })
    })
  }
  
  render() {
    return (
      <div className="mobile-home" style={{backgroundColor: '#EDEDED'}}>
        {
          !this.state.ske && this.state.mods.map(item => {
            const Com = MobileComponents[item.module_id]
            return Com && <Com key={item.sort} data={item} />
          })
        }
        {
          this.state.ske && 
          [1,2,3].map(item => {
            return (
              <Skeleton active loading key={item} />
            )
          })
        }
      </div>
    )
  }
}
