import React, { useRef, useEffect, useState } from "react";
import { WeiboOutlined, WechatOutlined } from "@ant-design/icons";
import { Form, Input, Button, Row, Col, message } from "antd";
import "./index.scss";
import { validatorPhone, validatorTrim } from "@utils/form";
import { subscribe, sendMsg } from "@/api/apis";
import gongan from "@assets/img/gongan.png";

export default function PcFooter(props) {
  const [form] = Form.useForm();
  const formRef = useRef();
  // 是否显示倒计时
  const [isSend, setIsSend] = useState(false);
  // 倒计时
  const [timerNum, setTimerNum] = useState(0);

  // 发送验证码
  const sendMsgEvent = () => {
    if (isSend) return;
    form.validateFields(["phone"]).then((data) => {
      sendMsg({
        data,
      }).then(() => {
        setIsSend(true);
        setTimerNum(60);
        message.success("短信发送成功");
      });
    });
  };

  // 发送验证码按钮倒计时
  useEffect(() => {
    let timer = 0;
    if (isSend && timerNum !== 0) {
      timer = setInterval(() => {
        // 这时候的num由于闭包的原因，一直是0，所以这里不能用setNum(num-1)
        setTimerNum((n) => {
          if (n === 1) {
            setIsSend(false);
            clearInterval(timer);
          }
          return n - 1;
        });
      }, 1000);
    }
    return () => {
      // 组件销毁时，清除定时器
      clearInterval(timer);
    };
  }, [isSend, timerNum]);

  // 提交
  const onFinish = (data) => {
    subscribe({
      data,
    })
      .then(() => {
        message.success("订阅成功");
        form.resetFields(); // 重置表单
        setIsSend(false);
        setTimerNum(0);
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  useEffect(() => {
    return function () {
      form.resetFields(); // 重置表单
    };
  }, [form]);

  return (
    <div className="pc-footer">
      <ul className="link-list">
        {props.navs.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {
                <li className="list-item">
                  <p className="list-item-title">{item.title}</p>
                  {item.children.map((itm, index2) => {
                    return (
                      <p className="link-item" key={index2}>
                        <a href={itm.ws_url} className="link-to">
                          {itm.title}
                        </a>
                      </p>
                    );
                  })}
                </li>
              }
            </React.Fragment>
          );
        })}
        {props.navs.length > 0 && (
          <li className="fx-col Newsletter list-item">
            <p className="list-item-title">订阅我们</p>
            <Form
              form={form}
              name="newsletter"
              ref={formRef}
              onFinish={onFinish}
              initialValues={{ phone: "", code: "", name: "" }}
              className="form-let"
            >
              {/* 手机号 */}
              <Form.Item
                label=""
                name="phone"
                size="small"
                rules={[
                  { required: true, message: "请输入手机号" },
                  { required: false, validator: validatorPhone },
                ]}
                validateTrigger="onBlur"
                className="sub-input"
              >
                <Input
                  size="small"
                  placeholder="手机号"
                  autoComplete="off"
                  bordered={false}
                />
              </Form.Item>
              {/* 验证码 */}
              <Form.Item label="" style={{ marginBottom: "0" }}>
                <Row>
                  <Col span={18}>
                    <Form.Item
                      name="code"
                      size="small"
                      rules={[
                        { required: true, message: "请输入验证码" },
                        { required: false, validator: validatorTrim },
                      ]}
                      validateTrigger="onBlur"
                      className="sub-input"
                    >
                      <Input
                        size="small"
                        placeholder="验证码"
                        autoComplete="off"
                        bordered={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Row justify="end">
                      <Col>
                        <Button
                          className="pc-btn-lg border-2px"
                          size="middle"
                          onClick={() => sendMsgEvent()}
                        >
                          {isSend ? timerNum : "获取验证码"}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item
                label=""
                name="name"
                size="small"
                rules={[
                  { required: true, message: "请输入用户名" },
                  { required: false, validator: validatorTrim },
                ]}
                validateTrigger="onBlur"
                className="sub-input"
              >
                <Input
                  size="small"
                  placeholder="姓名"
                  autoComplete="off"
                  bordered={false}
                />
              </Form.Item>

              <Form.Item label="">
                <Button type="primary" htmlType="submit" block>
                  订阅
                </Button>
              </Form.Item>
            </Form>
          </li>
        )}
      </ul>
      <div className="fx-sb align-b">
        <div className="fx">
          <span>
            ©{new Date().getFullYear()} 默瑟商贸（上海）有限公司 保留所有权利
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502018257"
            className="ml40 align-c"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="" src={gongan} className="gongan" />
            沪公网安备 31011502018257号
          </a>
          <a
            href="https://beian.miit.gov.cn/"
            className="ml40"
            target="_blank"
            rel="noreferrer"
          >
            沪ICP备2020035541号-1
          </a>
        </div>
        <div className="three-link">
          <a
            href="https://weibo.com/u/6629273396?is_all=1"
            className="wb-link"
            target="_blank"
            rel="noreferrer"
          >
            <WeiboOutlined />
          </a>
          <a href="/about/wechat.html" className="wx-link">
            <WechatOutlined />
            <img
              className="wx-img"
              alt=""
              src="https://moose-ows-res.ibaiqiu.com/moose/cms/20210901/151231.jpeg"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
