// 全局-订阅
import React, { useState, useEffect, useRef } from 'react';
import { Modal, Form, Button, Input, Row, Col, message } from 'antd';
import './index.scss';
import { getCookie, setCookie } from '@utils/index';
import { validatorPhone, validatorTrim } from '@utils/form';
import { subscribe, sendMsg } from '@/api/apis';
import BUS from '@utils/ev.js';


export default function Mob(props) {
  const [form] = Form.useForm();
  const formRef = useRef()
  // 弹框显示、隐藏
  const [show, setShow] = useState(getCookie('sub') !== '0')
  // 是否显示倒计时
  const [isSend, setIsSend] = useState(false)
  // 倒计时
  const [timerNum, setTimerNum] = useState(0)

  const sendMsgEvent = () => {
    if(isSend) return;
    form.validateFields(['phone']).then((data) => {
      sendMsg({
        data
      }).then(() => {
        setIsSend(true)
        setTimerNum(60)
        message.success('短信发送成功')
      })
    })
  }

  useEffect(() => {
    BUS.addListener('showSubscribe', (flag=true) => {
      setShow(flag)
    })
  }, [])
  
  // 发送验证码按钮倒计时
  useEffect(() => {
    let timer = 0
    if(isSend && timerNum !== 0) {
      timer = setInterval(() => {
        // 这时候的num由于闭包的原因，一直是0，所以这里不能用setNum(num-1)
        setTimerNum(n => {
          if (n === 1) {
            setIsSend(false)
            clearInterval(timer)
          }
         return n - 1
        });
      }, 1000)
    }
    return () => {
      // 组件销毁时，清除定时器
      clearInterval(timer)
    };
  }, [isSend, timerNum])

  // 提交
  const onFinish = (data) => {
    subscribe({
      data
    }).then(() => {
      message.success('订阅成功')
      setCookie('sub', '0', 15)
      setShow(false)
    }).catch(error => {
      message.error(error.message)
    })
  }
  
  // 组件销毁时清空表单
  useEffect(() => {
    return function() {
      if (!show) setIsSend(false); form.resetFields(); // 重置表单
    }
  }, [show, form])

  // 关闭弹框
  const onCancel = () => {
    setCookie('sub', '0', 15)
    setShow(false)
  }
  return (
    <Modal
      title=""
      footer={null}
      visible={show}
      maskClosable={true}
      bodyStyle={{padding: '0'}}
      onCancel={() => onCancel()}
      style={{top:'50px'}}
      className="mob-modal"
    >
    <div className="sub-modal-mob">
      <div className="sub-cont fx-col">
        <div className="sub-title">
          订阅我们
        </div>
          <Form
            form={form}
            name="register" 
            ref={formRef}
            onFinish={onFinish}
            initialValues={{ phpme: '', code: '', name: ''}}
          >
          <Form.Item
            label=""
            name="phone"
            size="small"
            rules={[
              { required: true, message: '请输入手机号' },
              { required: false, validator: validatorPhone },
            ]}
            validateTrigger= 'onBlur'
            className="sub-input"
          >
            <Input
              size="small"
              placeholder="手机号"
              autoComplete="off"
              bordered={false}
            />
          </Form.Item>
          <Form.Item label="" style={{marginBottom: '0'}}>
            <Row>
              <Col span={16}>
                <Form.Item
                  name="code"
                  size="small"
                  rules={[
                    { required: true, message: '请输入验证码' },
                    { required: false, validator: validatorTrim },  
                  ]}
                  validateTrigger= 'onBlur'
                  className="sub-input"
                >
                  <Input
                    size="small"
                    placeholder="验证码"
                    autoComplete="off"
                    bordered={false}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Row justify="end">
                  <Col span={22}>
                  <Button
                    block
                    size="mini"
                    onClick={() => sendMsgEvent()}
                  >
                    {
                      isSend ? timerNum : '获取验证码'
                    }
                  </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form.Item>
          {/* <Form.Item
            label=""
            name="email"
            size="small"
            rules={[
              { required: true, message: '请输入邮箱地址' },
              { required: false, validator: validatorEmail },
            ]}
            validateTrigger= 'onBlur'
            className="sub-input"
          >
            <Input
              size="small"
              placeholder="EMAIL"
              autoComplete="off"
              bordered={false}
            />
          </Form.Item> */}
          <Form.Item
            label=""
            name="name"
            size="small"
            rules={[
              { required: true, message: '请输入姓名' },
              { required: false, validator: validatorTrim },  
            ]}
            validateTrigger= 'onBlur'
            className="sub-input"
          >
            <Input
              size="small"
              placeholder="姓名"
              autoComplete="off"
              bordered={false}
            />
          </Form.Item>
          <Form.Item
            label=""
            style={{paddingTop: '20px',marginBottom: '60px',textAlign:'center'}}
          >
            <Button htmlType="submit" size="mini" className="pc-btn-lg" style={{width: '100%'}}>
              订阅
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
    </Modal>
  )
}