import React from 'react'
import useDevice from '@/hooks/useDevice';
import './index.scss';
// 品牌历史
export default function Error() {
  const device = useDevice()
  return (
    <div className={device === 'pc' ? 'pc-error' : 'mob-error'}>
        <div className="title">
          <div>404</div>
          <div className="oops">哎呀!</div>
        </div>
        <div className="sub-title">
          <div>您要找的页面不存在.</div>
          <div>返回首页！</div>
        </div>
    </div>
  )
}
