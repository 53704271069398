/* eslint-disable no-undef */
import React, { useEffect } from "react";
import { getStore } from "@/api/apis";

import "./index.scss";

const Map = (props) => {
  let map, marker, infoWindow;

  const TMapGL = () => {
    if (window.TMap) {
      return Promise.resolve();
    }
    return new Promise(function (resolve, reject) {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "https://map.qq.com/api/gljs?v=2.exp&libraries=tools&key=WH3BZ-UTF3B-ZQAUB-J7GZZ-H5ADO-TRB7D";
      script.onerror = () => reject();
      script.onload = () => resolve();
      document.head.appendChild(script);
    });
  };

  const initMap = async () => {
    // 开始加载腾讯地图gl文件
    await TMapGL();

    const { latitude, longitude } = {
      latitude: 39.915,
      longitude: 116.404,
    };

    // 设置地图中心点坐标
    const center = new window.TMap.LatLng(latitude, longitude);
    const idNameElement = document.getElementById("tMap");
    if (!idNameElement) {
      return;
    }
    map = new window.TMap.Map(document.getElementById("tMap"), {
      zoom: 5, // 设置地图缩放级别
      center, // 设置地图中心
    });

    let markerArr = []; // 标记点集合

    getStore({}).then((res) => {
      // 标记点聚合
      res.forEach((item) => {
        const store_latitude_longitude =
          item.store_latitude_longitude.split(",");
        if (store_latitude_longitude[1]) {
          markerArr.push({
            id: "cluster",
            styleId: "marker",
            position: new TMap.LatLng(
              store_latitude_longitude[1],
              store_latitude_longitude[0]
            ),
            properties: { ...item },
          });
        }
      });
      // 创建marker图层
      marker = new window.TMap.MultiMarker({
        map,
        styles: {
          // 点标记样式
          marker: new TMap.MarkerStyle({
            width: 20, // 样式宽
            height: 30, // 样式高
            anchor: { x: 10, y: 25 }, // 描点位置
          }),
        },
        geometries: markerArr,
      });

      // 创建信息窗口
      infoWindow = new window.TMap.InfoWindow({
        map,
        position: map.getCenter(),
        offset: {
          // 设置信息弹窗的偏移量，否则会和marker重合
          x: 0,
          y: -48,
        },
      }).close();
      const showInfo = (evt) => {
        let content = `
        <div class="task-info">
        <div class="info-title">
          ${evt.geometry.properties.store_name}
        </div>
        <div class="info-address">
          ${
            evt.geometry.properties.province +
            evt.geometry.properties.city +
            evt.geometry.properties.area +
            evt.geometry.properties.address
          }
        </div>
        <div class="info-service">${evt.geometry.properties.store_service}</div>
        
        <div class="info-tel" style={{fontSize: '14px'}}>
          <a href="tel:${
            evt.geometry.properties.shop_telephone
          }" class="txt-under">
            ${evt.geometry.properties.shop_telephone}
          </a>
        </div>
        </div>
        `;
        infoWindow.open();
        infoWindow.setPosition(evt.geometry.position); // 设置信息窗口的坐标
        infoWindow.setContent(content); // 设置信息窗口的内容
      };
      marker?.on("click", (evt) => {
        showInfo(evt);
      });
    });
  };

  useEffect(() => {
    initMap();
  }, []);

  return (
    <div
      className="mapCard-container"
      id="tMap"
      style={{
        width: "100vw",
        height: `calc(100vh - ${props.headerHeight || 0})`,
        overflow: "auto",
      }}
    ></div>
  );
};

export default Map;
