import React, { Component } from 'react'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'
import './index.scss'
import logo from '@assets/logo.svg'
import search from '@assets/img/search.png'
import user from '@assets/img/user.png'
import close from '@assets/img/close.png'
import nav from '@assets/img/nav.png'
import { Drawer } from 'antd';
import BUS from '@utils/ev.js';
import { searchNav } from '@/api/apis';
import {debounce} from '@utils'

export default class CommHead extends Component {
  state = {
    show: false,
    searchVal: '',
    isOpenSubNav: false,
    subNav: [],
    showFree: true, // 显示头部-免费配送
    showSearchResult: false, // 显示搜索结果
    searchResultList: []
  }
  

  componentDidMount() {
    this.scrollListener = () => {
      // 滚动距离超过100px
      if (window.scrollY > 20) {
        if(this.state.showFree){
          this.setState({
            showFree: false
          })
        }
      }

      if (window.scrollY <= 20) {
        if(!this.state.showFree){
          this.setState({
            showFree: true
          })
        }
      }
    }
    window.addEventListener('scroll', this.scrollListener)
  }

  // 显示、关闭侧边栏导航
  openNav = () => {
    this.setState({
      show: true
    })
  }

  onClose = () => {
    this.setState({
      show: false
    })
  }


  // 显示、关闭二级导航
  showSubNav = (data) => {
    this.setState({
      isOpenSubNav: true,
      subNav: data
    })
  }
  closeSubMenu = () => {
    this.setState({
      isOpenSubNav: false,
    })
  }

  // 显示订阅弹框
  showSubscribe = () => {
    BUS.emit('showSubscribe', true)
  }

  // 搜索
  
  searchEvent = (e) => {
    this.setState({
      searchVal: e.target.value,
    })
    this.getSearch(e.target.value)
  }

  getSearch = debounce((value) => {
    if(value) {
      searchNav({
        data: {
          key_words: value
        }
      }).then(res => {
        this.setState({
          showSearchResult: true,
          searchResultList: res.list
        })
      })
    }
  },1000)

  // 关闭搜索结果
  closeSearchReasult = () => {
    this.setState({
      searchVal: '',
      showSearchResult: false
    })
  }

  render() {
    return (
      <div className="mobile-header fx-col" style={{height: this.state.showFree ? '1.3751rem' : '0.88834rem'}}>
        <div className="h-top" style={{top: this.state.showFree ? '0' : '-0.48676rem'}}>中国(CNY) | 简体中文</div>
        <div className="h-bottom fx-sb align-c">
          <div className="logo">
            <a href="/index" className="logo-link">
              <img src={logo} alt="" className="logo-img" />
            </a>
          </div>
          <div className="btns">
            <img alt="" className="user-email" src={user} onClick={() => this.showSubscribe()} />
            <img alt="" src={nav} onClick={() => this.openNav()} />
          </div>
        </div>
      <Drawer
          title=""
          placement='right'
          closable={false}
          onClose={() => this.onClose()}
          visible={this.state.show}
          key='left'
          contentWrapperStyle={{width: '90vw'}}
          className="header-drawer"
          bodyStyle={{padding: '10px 0 0',background: '#dcddde'}}
        >
          {
            <div className="drawer-top" style={{height: this.state.isOpenSubNav ?'0': '0.55977rem'}}>
              <a href="/" className="head-left">
                <img className="drawer-logo" alt="" src={logo} />
              </a>
              <div className="head-right">
                <img alt="" src={close} onClick={() => this.onClose()} />
              </div>
            </div>
          }

          {/* 搜索框 */}
          {
            !this.state.isOpenSubNav && 
            <>
              <div className="search-form">
                <img alt="" src={search} className="search-icon" />
                <input className="search-input" onChange={(e) => this.searchEvent(e)} value={this.state.searchVal} />
                <img src={close} className="search-close-icon" alt="" onClick={() => this.closeSearchReasult()} />
              </div>

              {/* 搜索结果 */}
              {
                this.state.showSearchResult && 
                <div className="search-result">
                  <div className="res-title">搜索结果</div>
                  <ul className="res-link">
                  {
                    this.state.searchResultList.map((item, index) => {
                      return (
                        <li key={index}>
                          <a href={'/'+item.route}>{item.title}</a>
                        </li>
                      )
                    })
                  }
                  {
                    (this.state.showSearchResult && this.state.searchResultList.length===0) &&
                    <li>没有搜索到你想要的</li>
                  }
                  </ul>
                </div>
              }
              
            </>
          }

          {/* 显示一级菜单 */}
          {
            !this.state.isOpenSubNav && !this.state.showSearchResult && <div>
              <ul className="mob-nav">
                {
                  this.props.navs.map((item, index) => {
                    return (
                      <li className="fx-sb-c" key={index} onClick={() => this.showSubNav(item)}>
                        <span>{item.title}</span>
                        <RightOutlined />
                      </li> 
                    )
                  })
                }
              </ul>
              {
                !this.state.isOpenSubNav && 
                <a href="/store" className="footer-te footer-nav">
                精品店查询 
                </a>
              }
            </div>
          }
          {/* 显示二级菜单 */}
          {
            this.state.isOpenSubNav &&
            <div className="sub-nav">
              <div className="sub-nav-header fx-sb align-c">
                <div className="color000 align-c" onClick={() => this.closeSubMenu()}>
                  <LeftOutlined />
                  <span className="ml10">返回</span>
                </div>
                <div className="sub-title">
                  {this.state.subNav.title}
                </div>
                <img alt="" className="sub-height-close" src={close} onClick={() => this.onClose()} />
              </div>
              {
                this.state.subNav.children.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {
                        item.href_type === 'text' && 
                        <dl key={index}>
                          <dt>
                            <a href={item.ws_url}>
                              {item.title}
                            </a>
                          </dt>
                          {
                            item.children.map((itm, indx) => {
                              return (
                                <dd key={indx}>
                                  <a href={itm.ws_url} target={itm.ws_url_target || ''}>{itm.title}</a>
                                </dd>
                              )
                            })
                          }
                        </dl>
                      }
                      {
                        item.href_type === 'image' && 
                        <>
                        {
                          item?.children.map((im, ind) => {
                            return(
                              <a href={im.ws_url} key={ind} target={im.ws_url_target || ''}>
                                <img className="sub-img" alt="" src={im.mob_image_url} />
                              </a>
                            )
                          })
                        }
                        </>
                      }
                    </React.Fragment>
                  )
                })
              }
            </div>
          }
        </Drawer>
      </div>
    )
  }
}
