import React, { Component } from 'react'
import PubSub from 'pubsub-js'
import { subResize } from '../../utils'
import Pc from './pc'
import Mobile from './mobile'
import './index.scss'

const path = ['','index'].includes(window.location.pathname.slice(1))

export default class Header extends Component {
  componentDidMount () {
    this.subResizeToken = subResize(this.changeDevice)
  }

  componentWillUnmount () {
    PubSub.unsubscribe(this.subResizeToken)
  }

  changeDevice = device => {
    this.setState({ device })
  }

  state = {
    device: 'pc'
  }

  render() {
    const { device } = this.state
    let headerHeight
    if (device === 'mobile') {
      headerHeight = '1.3751rem'
    } else {
      if (path) {
        headerHeight = '0'
      } else {
        headerHeight = '1.89836rem'
      }
    }
    return (
      <header className="header" style={{height: headerHeight}}>
        {{ pc: <Pc />, mobile: <Mobile /> }[this.state.device]}
      </header>
    )
  }
}
