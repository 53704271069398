import PubSub from 'pubsub-js'
import md5 from 'js-md5'

// 发布resize事件
export const publishResize = () => {
  const terminals = { // 每个接口中有个参数terminal
    pc: 'Ws_Pc', // pc端
    mobile: 'Ws_Mob' // 移动端
  }

  const mobMaxWidth = 1080
  let device = window.innerWidth > mobMaxWidth ? 'pc' : 'mobile'

  let terminal = sessionStorage.getItem('terminal')
  const hasTem = terminal && terminal===terminals[device]
  if(!hasTem) sessionStorage.setItem('terminal', terminals[device])
  
  const resizeListener = () => {
    if (window.innerWidth > mobMaxWidth && device === 'mobile') {
      device = 'pc'
      PubSub.publish('resize', device)
    }
    if (window.innerWidth < mobMaxWidth && device === 'pc') {
      device = 'mobile'
      PubSub.publish('resize', device)
    }
    sessionStorage.setItem('terminal', terminals[device])
  }
  PubSub.publish('resize', device)
  window.addEventListener('resize', resizeListener, {
    once: true
  })
  return resizeListener
}

// 订阅resize事件
export const subResize = (cb) => {
  const subResizeToken = PubSub.subscribe('resize', (_, device) => {
    cb(device)
  })

  return subResizeToken
}


// 格式化url中查询字符串
export const formatQs = params => {
  if (!typeof(params) === 'object') {
    throw new Error('params is expected an object');
  }
  let qs = '?';
  Object.keys(params).forEach(key => {
    if (params[key]) {
      if (typeof(params[key]) === 'object') {
        Object.keys(params[key]).forEach(paramKey => {
          qs += `${key}[][${paramKey}=${params[key][paramKey]}]&`;
        });
      } else {
        qs += `${key}=${params[key]}&`;
      }
    }
  });
  qs = qs.substring(0, qs.length - 1);
  return qs;
};

// 设置cookie
export const setCookie = (key, value, day) => {
  if (typeof day === 'number') {
      let d = new Date();
      d.setDate(d.getDate() + day);
      document.cookie = `${key}=${value};expires=${d};path=/`;
  } else {
      document.cookie = `${key}=${value};path=/`;
  }
}

// 获取cookie
export const getCookie = (key) => {
  if (document.cookie) { // 判断是否有cookie
      let arr = document.cookie.split('; '); // 拆分所有cookie 
      for (let i = 0; i < arr.length; i++) {
          let item = arr[i].split('='); // 将cookie数据拆分成 key value
          // 通过key  查找value
          if (item[0] === key) return item[1]; // 找到key  返回value
      }
      return ''; // 如果循环结束 都没有 则返回空字符串
  }
}


// 参数签名
export const sign = (obj) => {
  const SIGN_KEY = 'ows';
  if (!Object.keys(obj).length) return md5(md5('') + SIGN_KEY).toLocaleUpperCase();
  const str = Object.keys(sortObjKey(obj)).reduce((s, key) => s += `${key}${obj[key]}`, '');
  const requestSign = md5(md5(str) + SIGN_KEY).toLocaleUpperCase();
  return requestSign;
}

// 对象key按字典升序排序
export const sortObjKey = (obj) => {
  if (typeof(obj) !== 'object') {
    throw new Error('params is expected an object');
  }
  const sortedKeys = Object.keys(obj).sort();
  const sortedObj = {};
  sortedKeys.forEach(key => sortedObj[key] = obj[key]);
  return sortedObj;
}

// 获取query
export const getQuery = str => {
  const query = {};
  const queryStr = str.split('?')[1]
  if (!queryStr) return query
  const queryArr = str.split('?')[1].split('&').filter(item => item);
  queryArr.forEach(q => query[q.split('=')[0]] = q.split('=')[1]);
  return query;
}

// 获取页面单个参数
export const getUrlSingleParams = key => {
  var reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]); return null;
}


//防抖
export function debounce(fn, wait, immediate=false) {
  let timer, startTimeStamp=0;
  let context, args;

  let run = (timerInterval)=>{
    timer= setTimeout(()=>{
      let now = (new Date()).getTime();
      let interval=now-startTimeStamp
      if(interval<timerInterval){ // the timer start time has been reset，so the interval is less than timerInterval
        startTimeStamp=now;
        run(wait-interval);  // reset timer for left time
      }else{
        if(!immediate){
          fn.apply(context,args);
        }
        clearTimeout(timer);
        timer=null;
      }

    },timerInterval);
  }

  return function() {
    context = this;
    args = arguments;
    let now = (new Date()).getTime();
    startTimeStamp = now; // set timer start time

    if (!timer) {
      if (immediate) {
        fn.apply(context, args);
      }
      run(wait);    // last timer alreay executed, set a new timer
    }
  }
}

// 节流
export const throttle = (fn, time = 500) => {
  let _lastTime = 0
  return function () {
      const _now = Date.now()
      if (_now - _lastTime >= time) {
          fn.apply(this, arguments)
          _lastTime = _now
      }
  }
}