// 全局-订阅
import React, { useState, useEffect, useRef } from 'react';
import { Modal, Form, Button, Input, Row, Col, message } from 'antd';
import './index.scss';
import { getCookie, setCookie } from '@utils/index';
import { validatorPhone, validatorTrim } from '@utils/form';
import { subscribe, sendMsg, getCms } from '@/api/apis';
import BUS from '@utils/ev.js';


export default function Pc(props) {
  const [form] = Form.useForm();
  const formRef = useRef()
  // 弹框显示、隐藏
  const [show, setShow] = useState(getCookie('sub') !== '0')
  // 是否显示倒计时
  const [isSend, setIsSend] = useState(false)
  // 倒计时
  const [timerNum, setTimerNum] = useState(0)
  const [img, setImg] = useState('')
  // 获取最新图片
  useEffect(() => {
    if(show) {
      getCms({
        params: {terminal: 'Ws_Pc', content_id: 'POP_Subscription', param_id: 'POP_Subscription'}
      }).then(res => {
        try {
          const ingurl = res.page_data[0]?.module_data[0]?.pc_source_url || 'http://staging.mooseknucklescanada.com.cn//static/images/moose/cms/20210819/popup-left-side-image.jpg'
          setImg(ingurl)
        } catch (error) {
          console.log(error);
        }
      })
    }
  }, [show])
 
  // 监听别的组件是否要求弹出弹框
  useEffect(() => {
    BUS.addListener('showSubscribe', (flag=true) => {
      setShow(flag)
    })
  }, [])

  // 发送验证码
  const sendMsgEvent = () => {
    if(isSend) return;
    form.validateFields(['phone']).then((data) => {
      sendMsg({
        data
      }).then(() => {
        setIsSend(true)
        setTimerNum(60)
        message.success('短信发送成功')
      })
    })
  }

  // 发送验证码按钮倒计时
  useEffect(() => {
    let timer = 0
    if(isSend && timerNum !== 0) {
      timer = setInterval(() => {
        // 这时候的num由于闭包的原因，一直是0，所以这里不能用setNum(num-1)
        setTimerNum(n => {
          if (n === 1) {
            setIsSend(false)
            clearInterval(timer)
          }
         return n - 1
        });
      }, 1000)
    }
    return () => {
      // 组件销毁时，清除定时器
      clearInterval(timer)
    };
  }, [isSend, timerNum])

  // 提交
  const onFinish = (data) => {
    subscribe({
      data
    }).then(() => {
      message.success('订阅成功')
      setCookie('sub', '0', 15)
      setShow(false)
    }).catch((error) =>{
      message.error(error.message)
    })
  }

  // 组件销毁时清空表单
  useEffect(() => {
    return function() {
      if (!show) setIsSend(false); form.resetFields(); // 重置表单
    }
  }, [show, form])

  // 关闭弹框
  const onCancel = () => {
    setCookie('sub', '0', 15)
    setShow(false)
  }
  return (
    <Modal
      title=""
      footer={null}
      visible={show}
      maskClosable={true}
      width="10.57rem"
      className="pc-modal"
      bodyStyle={{padding: '0'}}
      onCancel={() => onCancel()}
      centered
    >
    <div className="fx sub-modal">
      <img className="sub-img" src={img} alt=""></img>
      <div className="sub-cont">
        <div className="sub-title">
          订阅我们
        </div>
        <Form
          form={form}
          name="register" 
          ref={formRef}
          onFinish={onFinish}
          initialValues={{ phone: '', code: '', name: ''}}
        >
      {/* 手机号 */}
        <Form.Item
          label=""
          name="phone"
          size="small"
          rules={[
            { required: true, message: '请输入手机号' },
            { required: false, validator: validatorPhone },
          ]}
          validateTrigger= 'onBlur'
          className="sub-input"
        >
          <Input
            size="small"
            placeholder="手机号"
            autoComplete="off"
            bordered={false}
          />
        </Form.Item>
        {/* 验证码 */}
        <Form.Item label="" style={{marginBottom: '0'}}>
          <Row>
            <Col span={16}>
              <Form.Item
                name="code"
                size="small"
                rules={[
                  { required: true, message: '请输入验证码' },
                  { required: false, validator: validatorTrim }, 
                ]}
                validateTrigger= 'onBlur'
                style={{marginTop: '7px'}}
                className="sub-input"
              >
                <Input
                  size="small"
                  placeholder="验证码"
                  autoComplete="off"
                  bordered={false}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Row justify="end">
                <Col>
                  <Button
                    size="large"
                    className="pc-btn-lg fbold border-2px"
                    onClick={() => sendMsgEvent()}
                  >
                    {
                      isSend ? timerNum : '获取验证码'
                    }
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          label=""
          name="name"
          size="small"
          rules={[
            { required: true, message: '请输入用户名' },
            { required: false, validator: validatorTrim }, 
          ]}
          validateTrigger= 'onBlur'
          className="sub-input"
        >
          <Input
            size="small"
            placeholder="姓名"
            autoComplete="off"
            bordered={false}
          />
        </Form.Item>
        {/* 提交按钮 */}
        <Form.Item
          label=""
          style={{paddingTop: '30px'}}
        >
          <Button htmlType="submit" size="large" className="pc-btn-lg fbold border-2px">
            订阅
          </Button>
        </Form.Item>
      </Form>
      </div>
    </div>
    </Modal>
  )
}