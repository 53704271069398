/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react'
import { Carousel } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import * as BaseMedias from '../baseMedia'
import './index.scss'
import pxToRem from '@utils/htmlpxrem';

// 走马灯  图片/视频
export class CommSwiper extends Component {
  state = {
    carousel: React.createRef(),
    timerArr: []
  }
  
  componentDidMount() {
    let swith_time = this.props.data.module_data[0]?.swith_time > 0 ? this.props.data.module_data[0]?.swith_time : 3
    const timer = setTimeout(() => {
      this.state.carousel.current && this.state.carousel.current.next()
      this.setState({
        timerArr: []
      })
      clearTimeout(timer)
    }, swith_time*1000)
    this.setState({
      timerArr: [timer]
    })
  }

  beforeChange = (from, to) => {
    if(this.state.timerArr.length>0) {
      this.state.timerArr.forEach(t => {
        clearTimeout(t)
      })
    }
    let swith_time = this.props.data.module_data[to]?.swith_time > 0 ? this.props.data.module_data[to]?.swith_time : 5
    const timer = setTimeout(() => {
      this.state.carousel.current && this.state.carousel.current.next()
      this.setState({
        timerArr: []
      })
      clearTimeout(timer)
    }, swith_time*1000+500)
    this.setState({
      timerArr: [timer]
    })
  }

  changeCarousel = type => {
    return () => {
      this.state.carousel.current[type]()
    }
  }
  render () {
    return (
      <div className="img-carousel" style={this.props.data?.module_param?.css_style}>
        <Carousel
          autoplay={false}
          dots={false}
          ref={this.state.carousel}
          beforeChange={(from, to) => this.beforeChange(from, to)}
        >
          {
            this.props.data.module_data.map((item, index) => {
              return (
                <div className="fx">
                  <a href={item.ws_url} target={item?.ws_url_target || ''} className="carousel-item" key={index}>
                    {
                      <div className="good-img">
                        <BaseMedias.BaseMedia mediaData={item} isVideo={item?.show_type==='video'} />
                      </div>
                    }
                  </a>

                </div>
              )
            })
          }
        </Carousel>
        {
          this.props.data.module_data.length > 1 && 
          <LeftOutlined onClick={this.changeCarousel('prev')}/>
        }
        {
          this.props.data.module_data.length > 1 && 
          <RightOutlined onClick={this.changeCarousel('next')} />
        }
      </div>
    )
  }
}

// 1张图片/视频
export class ImgOne extends Component {
  render() {
    return (
      this.props.data.module_data.map((item, index) => {
        return (
        <a href={item.ws_url} target={item?.ws_url_target || ''} className="one-img fx" key={index} style={this.props.data?.module_param?.css_style}>
          <BaseMedias.BaseMedia mediaData={item} isVideo={item?.show_type==='video'} mod="oneimg"  />
        </a>
        )
      })
    )
  }
}

// 2张图片/视频
export class ImgTwo extends Component {
  state = {
    last_html : ''
  }
  componentDidMount() {
    if(this.props.data.module_param?.customize_html) {
      // 行内标签px转rem
      pxToRem(this.props.data.module_param?.customize_html || '').then(str => {
        this.setState({
          last_html: str
        })
      })
    }
  }
  render () {
    return (
      <div className="img-two-box" style={this.props.data?.module_param?.css_style}>
        {
          this.props.data.module_data.map((item,index) => {
            return (
              <div className="img-two fx-col">
                <a href={item.ws_url} target={item?.ws_url_target || ''} className="img-item fx" key={index}>
                  <BaseMedias.BaseMedia mediaData={item} isVideo={item?.show_type==='video'}/>
                </a>
              </div>
            )
          })
        }
        {
          (this.props.data.module_param?.module_layout_show === 'default' && this.state.last_html) &&
          <div
            className="float-center react-html"
            dangerouslySetInnerHTML={{__html: this.state.last_html}}
          >
          </div>
        }
      </div>
    )
  }
}

// 3张图片
export class ImgThree extends Component {
  state = {
    carousel: React.createRef(),
    last_html : ''

  }
  componentDidMount() {
    if(this.props.data.module_param?.customize_html) {
      // 行内标签px转rem
      pxToRem(this.props.data.module_param?.customize_html).then(str => {
        this.setState({
          last_html: str
        })
      })
    }
  }
  // 初始化
  changeCarousel = type => {
    return () => {
      this.state.carousel.current[type]()
    }
  }

  handleChangeImg = (type) => {
    return () => {
      this.state.carousel.current[type]()
    }
  }


  render () {
    return (
      <div className="imgthree" style={this.props.data?.module_param?.css_style}>
        <Carousel autoplay dots={false} ref={this.state.carousel}>
          {
            this.props.data.module_data.map((img, index) => {
              return (
                <div className="carousel-item" key={index}>
                  <a href={img.ws_url} target={img?.ws_url_target || ''}>
                    <BaseMedias.BaseMedia mediaData={img} isVideo={img?.show_type==='video'} />
                  </a>
                  <div className="good-info">
                    {
                      img?.title && <p className="good-name ellipsis">{img?.title}</p>
                    }
                    {
                      img?.price && <p className="good-price">{img?.price}</p>
                    }
                  </div>
                </div>
              )
            })
          }
        </Carousel>
        {
          this.props.data.module_data.length > 1 && 
          <LeftOutlined onClick={this.changeCarousel('prev')}/>
        }
        {
          this.props.data.module_data.length > 1 && 
          <RightOutlined onClick={this.changeCarousel('next')} />
        }
        {
          this.props.data.module_param?.module_layout_show === 'default' && 
          this.state.last_html &&
          <div className="footer-menu">
            <div
              className="react-html default-html"
              dangerouslySetInnerHTML={{__html: this.state.last_html}}
            >
            </div>

          </div>
        }
      </div>
    )
  }
}

// 4张图片
export class ImgFour extends Component {
  state = {
    carousel: React.createRef(),
    last_html : ''

  }
  componentDidMount() {
    if(this.props.data.module_param?.customize_html) {
      // 行内标签px转rem
      pxToRem(this.props.data.module_param?.customize_html).then(str => {
        this.setState({
          last_html: str
        })
      })
    }
  }
  // 初始化
  changeCarousel = type => {
    return () => {
      this.state.carousel.current[type]()
    }
  }

  handleChangeImg = (type) => {
    return () => {
      this.state.carousel.current[type]()
    }
  }


  render () {
    return (
      <div className="imgfour" style={this.props.data?.module_param?.css_style}>
        <Carousel autoplay dots={false} ref={this.state.carousel}>
          {
            this.props.data.module_data.map((img, index) => {
              return (
                <div className="carousel-item" key={index}>
                  <a href={img.ws_url} target={img?.ws_url_target || ''}>
                    <BaseMedias.BaseMedia mediaData={img} isVideo={img?.show_type==='video'}  />
                  </a>
                  <div className="good-info">
                    {
                      img?.title && <p className="good-name ellipsis">{img?.title}</p>
                    }
                    {
                      img?.price && <p className="good-price">{img?.price}</p>
                    }
                  </div>
                </div>
              )
            })
          }
        </Carousel>
        {
          this.props.data.module_data.length > 1 && 
          <LeftOutlined onClick={this.changeCarousel('prev')}/>
        }
        {
          this.props.data.module_data.length > 1 && 
          <RightOutlined onClick={this.changeCarousel('next')} />
        }
        {
          this.props.data.module_param?.module_layout_show === 'placeholder_right' && 
          this.state.last_html &&
          <div className="footer-menu">
            <div
              className="react-html"
              dangerouslySetInnerHTML={{__html: this.state.last_html}}
            >
            </div>

          </div>
        }
      </div>
    )
  }
}

// pc显示5张图片,mob显示1张图片-轮播
export class ImgFiveSwiper extends Component {
  state = {
    carousel: React.createRef(),
  }
  // 初始化
  changeCarousel = type => {
    return () => {
      this.state.carousel.current[type]()
    }
  }

  handleChangeImg = (type) => {
    return () => {
      this.state.carousel.current[type]()
    }
  }


  render () {
    return (
      <div className="imgFive" style={this.props.data?.module_param?.css_style}>
        <Carousel autoplay dots={false} ref={this.state.carousel}>
          {
            this.props.data.module_data.map((img, index) => {
              return (
                <div className="carousel-item" key={index}>
                  <a href={img.ws_url} target={img?.ws_url_target || ''}>
                    <BaseMedias.BaseMedia mediaData={img} isVideo={img?.show_type==='video'}  />
                  </a>
                  <div className="good-info">
                    {
                      img?.title && <p className="good-name">{img?.title}</p>
                    }
                    {
                      img?.price && <p className="good-price">{img?.price}</p>
                    }
                  </div>
                </div>
              )
            })
          }
        </Carousel>
        <LeftOutlined onClick={this.changeCarousel('prev')} style={{color: 'rgba(250, 250, 250, .1)'}} />
        <RightOutlined onClick={this.changeCarousel('next')} style={{color: 'rgba(250, 250, 250, .1)'}} />
      </div>
    )
  }
}

// 纯文字模块
export class FloatHtml extends Component {
  state = {
    module_data: []
  }
  componentDidMount() {
    this.props.data.module_data.forEach(item => {
      pxToRem(item?.layout?.customize_html ||item?.customize_html || '').then(str => {
        this.setState({
          module_data: [...this.state.module_data, str]
        })
      })
    })
  }
  render () {
    return (
      this.state.module_data.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {
              item &&
              <div
                style={this.props.data?.module_param?.css_style}
                className="empty-block react-html"
                dangerouslySetInnerHTML={{__html: item}}
              >
              </div>
            }
          </React.Fragment>
        )
      })
    )
  }
}

// 1张图+文字---两栏位
export class TextImgHtml extends Component {
  state = {
    module_data: []
  }
  componentDidMount() {
    this.props.data?.module_data.forEach(item => {
      let itemData = Object.assign({} ,item)
      pxToRem(item?.layout?.customize_html ||item?.customize_html || '').then(res => {
        itemData.customize_html = res;
        this.setState({
          module_data: [...this.state.module_data, itemData]
        })
      })
    })
  }
  render () {
     // arrange=1图片在左边，4图片在右边
    return (
      this.state?.module_data.map((item,index) => {
        return (
          <div className="img-one-plus-text fx-col" key={index} style={this.props.data?.module_param?.css_style}>
            {
              ['text2image', 'timage2btext', 'image2text', 'image'].includes(item?.arrange) && 
              <img className="img" alt="" src={item.mob_source_url}></img>
            }
            {
              ['video2text', 'video'].includes(item?.arrange) &&
              <video className="video" alt="" src={item.mob_source_url} controls preload />
            }
            {
              item.title && 
              <div className="title">
                {item.title}
              </div>
            }
            {
              item.content && 
              <div className="react-html empty-block" dangerouslySetInnerHTML={{__html: item.content}}></div>
            }
          </div>
        )
      })
    )
  }
}