/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import './index.scss'

export default class MobWarmaf extends React.Component {
  render() {
    return (
      <div className="mob-warmaf">
        <section className="top1">
          <div className="one-screen">
            <div className="title upper colorAD9">
              Honestly, <br />We’re WARM AF
            </div>
            <p className="sub-title">Embracing style without<br />sacrificing function</p>
            <div className="more-cta fx-col align-c">
              <p>Don’t take our word <br />for it</p>
              <span className="banner-arrow"></span>
            </div>
          </div>
          <div className="top1-btm">
            <a href="https://www.retail-insider.com/retail-insider/2018/8/moose-knuckles-rapidly-expanding-moose-knuckles-parka-ranks-tops-amongst-competitors-for-thermal-insulation/" target="_blank">
              <p className="quote">
              “Moose Knuckles […] has been ranked as the top parka brand in terms 
              of having the highest thermal insulation value.”
              </p>
            </a>
            <a href="https://www.retail-insider.com/retail-insider/2018/8/moose-knuckles-rapidly-expanding-moose-knuckles-parka-ranks-tops-amongst-competitors-for-thermal-insulation/" target="_blank">
              <p className="quotee">– Retail Insider magazine, August 2018</p>
            </a>
            <a href="https://www.retail-insider.com/retail-insider/2018/8/moose-knuckles-rapidly-expanding-moose-knuckles-parka-ranks-tops-amongst-competitors-for-thermal-insulation/" target="_blank">
              <p className="fineprint">Based on comparison between Moose Knuckles’ Core parka and three comparable core coats of its competitors.</p>
            </a>
          </div>
        </section>

        <section className="top2 align-c fx-col">
          <div className="title colorAD9">A DETAILED LOOK</div>
          <div className="colorAD9 the-shell">THE SHELL</div>
          <p className="the-keep">Fabrics that keep you warm no matter the element</p>
          <div className="fx-col">
            <div className="left fx">
              <img className="left-img" alt="" src="https://moose-ows-res.ibaiqiu.com/cms/warmaf/layers.png" />
              <ul>
                <li>Durable front and back. Water repellent exterior finish, coupled with a waterproof laminated backing.</li>
                <li>Down-proof baffle layer</li>
                <li>We use lightweight duck and goose down insulations with superior fill power to keep you warm AF!</li>
                <li>Down-proof baffle layer (feathers won’t poke through).</li>
                <li>Though lining built to be tear and snag-proof.</li>
              </ul>
              <span className="tip">If you understand the above then you know how warm this coat is</span>
            </div>
            <div className="right">
              <span className="tips">Moose Knuckles uses certified, 100% ethically sourced premium RDS<sup>*</sup> ducking goose downs to ensure superior performance and quality. Higher fill power provides maximum warmth without weighing you down. We set the standard by using fill powers ranging from 650+ to 800 cc’s.</span>
              <img alt="" src="https://moose-ows-res.ibaiqiu.com/cms/warmaf/feathers.png" />
              <div className="equal">EQUAL WEIGHT</div>
              <div className="fp-class">
                <div>400FP</div>
                <div>500FP</div>
                <div>650+FP</div>
                <div>800FP</div>
              </div>
              <div className="fp-desc">
                <div className="desc-one">(Less fill, more chill)</div>
                <div className="desc-two">Moose Knuckles Classic Core Coats</div>
                <div className="desc-three">Moose Knuckles Mid-Core Coats</div>
              </div>
              <div className="pdl10">
                <span className="colorAD9 f16">NO CHILL</span> *RDS (Responsible Down Standard)
              </div>
            </div>
          </div>
        </section>

        <section className="top3">
          <div className="pd90">
            <div className="title upper">The perfect zipper</div>
            <div className="metal">
              <div className="left">
                <dl>
                  <dt>Moose Knuckles custom full metal zipper:</dt>
                  <dd>Heavy duty</dd>
                  <dd>Water-repellent</dd>
                  <dd>Unbreakable metal</dd>
                  <dd>Freeze and rust proof</dd>
                </dl>
              </div>
              <div className="right">
                <ul className="top-imgs fx-sc">
                  <li className="fx-col align-c">
                    <img alt="" src="https://moose-ows-res.ibaiqiu.com/cms/warmaf/zipper-step-one.png" />
                    <span>1</span>
                  </li>
                  <li className="fx-col align-c">
                    <img alt="" src="https://moose-ows-res.ibaiqiu.com/cms/warmaf/zipper-step-two.png" />
                    <span>2</span>
                  </li>
                  <li className="fx-col align-c">
                    <img alt="" src="https://moose-ows-res.ibaiqiu.com/cms/warmaf/zipper-step-three.png" />
                    <span>3</span>
                  </li>
                  <li className="fx-col align-c">
                    <img alt="" src="https://moose-ows-res.ibaiqiu.com/cms/warmaf/zipper-step-four.png" />
                    <span>4</span>
                  </li>
                </ul>
                <ul className="btm-txt">
                  <li>1.Align top and bottom zipper sliders</li>
                  <li>2.Fully insert pin into two-way zipper sliders</li>
                  <li>3.Hear sharp click</li>
                  <li>4.Pull up top zipper slide</li>
                  <li>5.Rinse and repeat for easier use</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        
        <section className="top4">
          <ul className="sage fx-col">
            <li className="fx">
              <img alt="" src="https://moose-ows-res.ibaiqiu.com/cms/warmaf/feature-one.png" />
              <span>We primarily use Saga-certified Finnish fox hand-sewn in Canada. Saga sets the highest standard worldwide for ethically and sustainably produced furs.</span>
            </li>
            <li className="fx">
              <img alt="" src="https://moose-ows-res.ibaiqiu.com/cms/warmaf/feature-two.png" />
              <span>Interior pocket to keep your valuables secure and accessible.</span>
            </li>
          </ul>
          <ul className="sage fx-col">
            <li className="fx">
              <img alt="" src="https://moose-ows-res.ibaiqiu.com/cms/warmaf/feature-three.png" />
              <span>Most styles feature interior and exterior storm plackets to keep the wind out.</span>
            </li>
            <li className="fx">
              <img alt="" src="https://moose-ows-res.ibaiqiu.com/cms/warmaf/feature-four.png" />
              <span>Heavy duty yet ultra-soft and cozy protective luxe pocketing - the toughest and warmest around.</span>
            </li>
          </ul>
          <ul className="sage fx-col">
            <li className="fx">
              <img alt="" src="https://moose-ows-res.ibaiqiu.com/cms/warmaf/feature-five.png" />
              <span>Luxe anti - pill knit cuff with stretch and recovery technology.</span>
            </li>
            <li className="fx">
              <img alt="" src="https://moose-ows-res.ibaiqiu.com/cms/warmaf/feature-six.png" />
              <span>Most styles include interlining bolts at the shoulder and upper-arm to ensure warmth at critical heat-loss spots. No more cold shoulder.</span>
            </li>
          </ul>
          <ul className="sage fx-col">
            <li className="fx">
              <img alt="" src="https://moose-ows-res.ibaiqiu.com/cms/warmaf/feature-seven.png" />
              <span>Luxe down insulation in all the right places. Each down pillow has a 4-ply construction ensuring against down loss, migration, and penetration. In other words, warmth stays in, cold stays out.</span>
            </li>
            <li className="fx">
              <img alt="" src="https://moose-ows-res.ibaiqiu.com/cms/warmaf/feature-eight.png" />
              <span>Handsewn reinforcements throughout. Every snap, rivet, corner and bar-tack is reinforced against wear and tear. Same method used in tents (kind of).</span>
            </li>
          </ul>
          <ul className="sage fx-col">
            <li className="fx">
              <img alt="" src="https://moose-ows-res.ibaiqiu.com/cms/warmaf/feature-nine.png" />
              <span>Inside security pocket with zip closure.</span>
            </li>
          </ul>
        </section>

        
        <section className="top5">
          <div className="title">Made in Canada with the finest Canadian and imported materials</div>
          <div className="pay">
            <img alt="" src="https://moose-ows-res.ibaiqiu.com/cms/warmaf/phone-icon.png" />
            <img alt="" src="https://moose-ows-res.ibaiqiu.com/cms/warmaf/rfid-icon.png" />
          </div>
          <div className="text-cont">Scan the RFID (inside, behind care and content label) to find out more about this Moose Knuckles item and to confirm its authenticity.</div>
          <div className="text-cont">Technologically challenged?…no worries, visit this page.</div>
        </section>
        
        <section className="btm">
          <div className="title">SHOW US HOW<br />YOU WEAR YOUR MOOSE</div>
          <div className="link fx-col">
            <a href="/index">@mooseknucklescanada</a>
            <a href="/index">#moosebringstheheat</a>
          </div>
        </section>
      </div>
    );
  }
}