/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useRef } from "react";
import { Collapse, Form, Button, Input, Row, Col, message } from "antd";
import { WeiboOutlined, WechatOutlined } from "@ant-design/icons";
import { validatorPhone, validatorTrim } from "@utils/form";
import { subscribe, sendMsg } from "@/api/apis";
import gongan from "@assets/img/gongan.png";
import "./index.scss";

const { Panel } = Collapse;
export default function MobileFooter(props) {
  const [form] = Form.useForm();
  const formRef = useRef();
  // 是否显示倒计时
  const [isSend, setIsSend] = useState(false);
  // 倒计时
  const [timerNum, setTimerNum] = useState(0);

  const sendMsgEvent = () => {
    if (isSend) return;
    form.validateFields(["phone"]).then((data) => {
      sendMsg({
        data,
      }).then(() => {
        setIsSend(true);
        setTimerNum(60);
        message.success("短信发送成功");
      });
    });
  };

  // 发送验证码按钮倒计时
  useEffect(() => {
    let timer = 0;
    if (isSend && timerNum !== 0) {
      timer = setInterval(() => {
        // 这时候的num由于闭包的原因，一直是0，所以这里不能用setNum(num-1)
        setTimerNum((n) => {
          if (n === 1) {
            setIsSend(false);
            clearInterval(timer);
          }
          return n - 1;
        });
      }, 1000);
    }
    return () => {
      // 组件销毁时，清除定时器
      clearInterval(timer);
    };
  }, [isSend, timerNum]);

  // 提交
  const onFinish = (data) => {
    subscribe({
      data,
    })
      .then(() => {
        message.success("订阅成功");
        form.resetFields(); // 重置表单
        setIsSend(false);
        setTimerNum(0);
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  useEffect(() => {
    return function () {
      form.resetFields(); // 重置表单
    };
  }, [form]);

  return (
    <div className="mobile-footer">
      <Collapse ghost>
        <Panel header="订阅我们" key="0">
          <Form
            form={form}
            name="register"
            ref={formRef}
            onFinish={onFinish}
            initialValues={{ phpme: "", code: "", name: "" }}
            className="mob-footer-form"
          >
            <Form.Item
              label=""
              name="phone"
              size="small"
              rules={[
                { required: true, message: "请输入手机号" },
                { required: false, validator: validatorPhone },
              ]}
              validateTrigger="onBlur"
              className="sub-input"
            >
              <Input
                size="small"
                placeholder="手机号"
                autoComplete="off"
                bordered={false}
              />
            </Form.Item>
            <Form.Item label="" style={{ marginBottom: "0" }}>
              <Row>
                <Col span={16}>
                  <Form.Item
                    name="code"
                    size="small"
                    rules={[
                      { required: true, message: "请输入验证码" },
                      { required: false, validator: validatorTrim },
                    ]}
                    validateTrigger="onBlur"
                    className="sub-input"
                  >
                    <Input
                      size="small"
                      placeholder="验证码"
                      autoComplete="off"
                      bordered={false}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Row justify="end">
                    <Col span={22}>
                      <Button block size="mini" onClick={() => sendMsgEvent()}>
                        {isSend ? timerNum : "获取验证码"}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              label=""
              name="name"
              size="small"
              rules={[
                { required: true, message: "请输入姓名" },
                { required: false, validator: validatorTrim },
              ]}
              validateTrigger="onBlur"
              className="sub-input"
            >
              <Input
                size="small"
                placeholder="姓名"
                autoComplete="off"
                bordered={false}
              />
            </Form.Item>
            <Form.Item
              label=""
              style={{
                paddingTop: "10px",
                marginBottom: "40px",
                textAlign: "center",
              }}
            >
              <Button
                htmlType="submit"
                size="mini"
                style={{ width: "100%" }}
                className="mob-btn-lg"
              >
                订阅
              </Button>
            </Form.Item>
          </Form>
        </Panel>
        {props.navs.map((item, index) => {
          return (
            <Panel header={item.title} key={index + 1}>
              {item?.children.map((itm, indx) => {
                return (
                  <p key={indx}>
                    <a href={itm.ws_url}>{itm.title}</a>
                  </p>
                );
              })}
            </Panel>
          );
        })}
      </Collapse>
      <div className="share-btn">
        <a
          href="https://weibo.com/u/6629273396?is_all=1"
          className="wb-link"
          target="_blank"
          rel="noreferrer"
        >
          <WeiboOutlined />
        </a>
        <a href="/about/wechat.html" className="wx-link">
          <WechatOutlined />
        </a>
      </div>
      <div className="fx-col align-c mt20">
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502018257"
          className="ml40 align-c"
          target="_blank"
          rel="noreferrer"
        >
          <img alt="" src={gongan} className="gongan" />
          沪公网安备 31011502018257号
        </a>
        <a href="https://beian.miit.gov.cn/" className="ml40 mt5 mb5">
          沪ICP备2020035541号-1
        </a>
        <span target="_blank" rel="noreferrer">
          ©{new Date().getFullYear()} 默瑟商贸（上海）有限公司 保留所有权利
        </span>
      </div>
    </div>
  );
}
