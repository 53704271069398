import React, { Component } from 'react'
import Header from './layout/Header'
import * as views from './views'
import Footer from './layout/Footer'
import './App.scss'
import { publishResize } from './utils'
import Subscribe from '@components/Subscribe';

export default class App extends Component {
  componentDidMount () {
    this.resizeListener = publishResize()
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.resizeListener)
  }

  render () {
    const View = views[this.page.body.content_id]
    return (
      <div className="App">
        <Header />
        <View />
        <Footer />
        {/* 订阅功能 */}
        <Subscribe />
      </div>
    )
  }
}
